$beige: #F8F8F8;
$black: #1D2226;
$title-font: PPAgrandir;
$general-text-font: 'DM Sans', sans-serif;


@font-face {
  font-family: 'PPAgrandir';
  src: url('../assets/Agandir\ Font/PPAgrandir-NarrowRegular.otf') format('truetype');
  font-weight: 470;
}

* {
  box-sizing: border-box;
  font-family: $general-text-font;
  margin: 0;
}

*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}


.no-show {
  display:none;
}

.light {
  background-color: $beige;
  color: $black;
}

.dark {
  background-color: $black;
  color: $beige;
}

.icon {
  width: 30px;
}


.caps {
  text-transform: uppercase;
}

.title-font {
  font-family: $title-font, medium;
}

.section-title {
  flex: 2;
}

.info-text {
  flex:3;
  font-size: 120%;
  // margin-top: 5%;
}

h1 {
  font-size: 600%;
}

.medium-title {
  font-size: 200%;
  text-align: center;
}


.component-container {
  display: flex;
  justify-content: space-evenly;
  // align-items: center;
  height: 100vh;
  width: 100vw;
}

.column-flex-container {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  z-index: 5;
}

.separated-columns {
  justify-content: stretch;
}

.grid-flex-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin: 5% 0%;
}

.row-flex-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-wrap: nowrap;
  gap: 5%;
  width: 90%;
  margin: 5% 0%;
}

.column-reverse-flex-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 5%;
  width: 90%;
  margin: 5% 0%;
}

.flex-row-centered {
  margin: 0;
  margin-top: 8%;
  align-items: flex-start;
  z-index: 1;
}

.title-container {
  max-width: 100%;
}


.hero-text {
  display: none;
  font-size: 16px;
  margin-bottom: 20px;
  max-width: 600px;
  text-align:justify;
  flex-wrap: wrap;
}

.hero-button {
  font-size: 100%;
  flex-grow: 1;
  padding: 7px 25px;
  background-color: $black;
  color: $beige;
  border: 2px solid $black;
  border-radius: 2px;
  width: 25%;
  flex-basis: calc(50% - 40px);
  text-align: center;
  &:hover {
    background-color: $beige;
    border: 2px solid $black;
    color: $black;
    border-radius: 2px;
    // background-color: #002fa7;
  }
}

#alara {
  max-width: 50%;
  margin-top: 5%;
}

.break {
  width: 99%;
  border: 2px solid $black;
  margin-top: 5%;
}

.location-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  min-height: 35px;
}

.section-break {
  width: 101%;
  border: 80px solid $black;
  background-color: $black;
  -webkit-transform: translateY(-50px) translateX(0px) rotate(1.2deg);
  transform: translateY(-50px) translateX(0px) rotate(3.0deg);
  position: absolute;
  overflow-x: hidden;
  right: -1px;
  @media only screen and (max-width: 1095px){
      display: none;
    }
}

.section-break-left {
  width: 101%;
  border: 80px solid $beige;
  background-color: $beige;
  -webkit-transform: translateY(-50px) translateX(0px) rotate(-1.2deg);
  transform: translateY(-50px) translateX(0px) rotate(-3.0deg);
  position: absolute;
  overflow-x: hidden;
  right: -17.5px;
  
  @media only screen and (max-width: 1095px){
      display: none;
    }
}

.section-break-horizontal {
  -moz-transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  border: $black 30px solid;
  left: -2px;
}

.hero-contact-links img {
  min-width: 33%;
}

.contact-links {
  text-decoration: none;
  color: $beige;
  width: 70%;
  i {
    width: 180%;
  }
  p {
    display: inline;
  }
  @media only screen and (max-width: 1095px){
    width:unset;
  }
}

//****NAVBAR****//

.navbar {
  width: 100vw;
  position: fixed;
  z-index: 7;
}

.navbar-arrow {
  font-size: 200%;
  background-color: $black;
  color: $beige;
  border-radius: 50%;
  margin: 82vh 10px 10px 95vw;
  position: fixed;
  z-index: 7;
}
.navbar-flex-container {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  background-color: $beige;
  margin: 0;
  // position: fixed;
  
  .navbar-links-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 2;
    
  }
}

.navbar-link {
  margin: 0 2%;
  color: $black;
  &:hover {
    text-decoration: underline;
  }
}

.navbar-dark {
  color: $beige;
  background-color: $black;
}

.cursor {
  cursor: pointer;
}

//****FOOTER****//

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vh;
}

//****ABOUT****//


.blob {
  width: 70%;
  cursor: pointer;
  &:hover {
    width: 73%;
  }
}

.blob-inner {
  position: relative;
  text-align: center;
}

.centered-blob-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $black;
  font-family: $title-font;
  font-size: 100%;

  
}

.left-blob {
  left: 49%;
  right: 10%;
}

.right-blob {
  left: 47%;
}

//****SKILLS****//

.text-aligned-right {
  width: 50%;
  margin-left: 20vw;
  // margin-bottom: 30%;
  margin-top: 10%;
  font-size: 550%;
}

#skills {
  height: 110vh;
}

.separate {
  width: 100vw;
  justify-content: center;
  margin-top: 4%;
  z-index: 5;
  h1{
    margin-left: 40vw;
    margin-right: 0;
    padding-right: 0;
  }
  .skills-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 3%;
    z-index: 10;
    .skills{
      width: 20%;
      .skills-header {
        font-size: 200%;
        text-align: center;
        color: $beige;
        background-color: $black;
        border: 2px $black solid;
      border-radius: 4px;
      }
      .skills-list{
        border: 2px $black solid;
        border-radius: 4px;
        margin-top: 0%;
        padding-left: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        li{
          list-style-type: '-';
          text-align: justify;
          padding: 2.5%;
          font-size: 120%;
        }
      } 
    }
    .flower {
      height: 80px;
      z-index: 4;
      margin: 0;
    }
  }

}



//****CONTACT****//

#contact {
  height: 70vh;
}

.contact-icons {
  margin-top: 5%;
  margin-right: 5%;
  width: 100%;
  flex:1;
  font-size: 200%;
  .contact-links {
    margin: 2.5%;
    .contact-icon{
      width: 30%;
      padding: 5%;
    }
  }
  
}

.email-icon {
  display:unset;
  font-size: 36px;
}
.white {
  color: white;
}




//* PROJECTS

#projects {
  height: 110vh;
  h1 {
    margin-top: 2%;
  }
  .makeStyles-root-1, .jss1 {
    width: 85%;
    height: 75%;
    
    .MuiPaper-root {
      margin-top: 3%;
      height: 100%;
      padding: 5%;
      background-color: rgba(248, 248, 248, 1);
      // background-color: $black;
      
    }
    .project-card {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
  
      h2 {
        font-family: $title-font;
        margin-bottom: 2%;
        color: $black;
        text-decoration: underline;
      }
      
      .project-img {
        width: 85%;
        height: 75%;
        // border: $beige 1px solid;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.596) 0px 3px 6px 1px;
      }
      .tech-icon-container {
        justify-content: center;
        align-items: center;
        gap: 2%;
        margin: 3%;
      }
      .icon-item {
        margin: 0;
        i {
          font-size: 225%;
          color: rgb(73, 73, 73);
        }
      }
      h3 {
        font-family: $title-font;
        text-align: center;
      }
      a {
        text-decoration: none;
        font-size: 100%;
        flex-grow: 1;
        margin-top: 9%;
        padding: 8px 25px;
        color: $black;
        background-color: $beige;
        border: 2px solid $black;
        border-radius: 2px;
        width: 25%;
        flex-basis: calc(50% - 40px);
        text-align: center;
        i {
          font-size: 130%;
        }
        &:hover {
          background-color: $black;
          color: $beige;
          border: 2px solid $black;
          border-radius: 2px;
          // background-color: #002fa7;
    }
        
      }
    }
    
  }
}

//* EXPERIENCE

#experience {
  h1 {
    margin:-1%;
    margin-top: 1%;
    padding: 0;
    flex:0;
  }
  .experience-section {
    max-height: 80vh;
  }
  .timeline {
    position: relative;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 15px 0;
  }
  
  .timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background: $black;
    background: $black;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
  }
  
  .experience-container {
    padding: 0 30px;
    position: relative;
    background: inherit;
    width: 50%;
  }
  
  .experience-container.left {
    left: 0;
  }
  
  .experience-container.right {
    left: 50%;
  }
  
  .experience-container::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    right: -8px;
    background: $beige;
    border: 2px solid $black;
    border-radius: 16px;
    z-index: 1;
  }
  
  .experience-container.right::after {
    left: -8px;
  }
  
  .experience-container::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    top: calc(50% - 1px);
    right: 8px;
    background: $black;
    z-index: 1;
  }
  
  .experience-container.right::before {
    left: 8px;
  }
  
  .experience-container .content {
    padding: 30px 90px 30px 30px;
    background: $black;
    position: relative;
    border-radius: 3px;
  }
  
  .experience-container.right .content {
    padding: 30px 30px 30px 90px;

  }
  a {
    text-decoration: none;
  }
  .experience-container .content h2, .experience-container .content h6 {
    font-family: $title-font;
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: normal;
    color: #D2DEE1;
  }

  .experience-container .content h6 {
    font-style: italic;
    font-size: 14px;
  }
  
  .experience-container .content p {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: $beige;
  }

  
  @media (max-width: 767.98px) {
    .timeline::after {
      left: 90px;
    }
  
    .experience-container {
      width: 100%;
      padding-left: 120px;
      padding-right: 30px;
    }
  
    .experience-container.right {
      left: 0%;
    }
  
    .experience-container.left::after, 
    .experience-container.right::after {
      left: 82px;
    }
  
    .experience-container.left::before,
    .experience-container.right::before {
      left: 100px;
      border-color: transparent $black transparent transparent;
    }

  
    .experience-container.left .content,
    .experience-container.right .content {
      padding: 30px 30px 30px 30px;
      border-radius: 3px;
      font-size:13px;
    }
  }

  .linkedin-redirect-text {
    text-align: center;
    i {
      font-size: 300%;
    }
    &:hover {
      background-color: $beige;
      border: 2px solid $black;
      
      border-radius: 2px;
      p {
        color: $black;
      }
    }
    }
}



#hero {
  @media only screen and (max-width: 1149px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 300%;
    }
    h4 {
      font-size: 100%;
    }
    #alara {
      max-width: 20%;
    }
    .title-container {
      margin-top: 15px;
    }
    .grid-flex-container {
      margin: 5% 7%;
    }
    .grid-flex-container, .column-flex-container, .hero-text {
      max-width: 80%;
    }

  }
}

#about {

    @media only screen and (max-width: 1149px){
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 200vh;
      .row-flex-container {
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 300%;
          text-align: center;
        }
        .info-text {
          flex:3;
          font-size: 100%;
          // margin-top: 5%;
        }
        .centered-blob-text {
          font-size: 70%;
        }
      }
      
      
    }
  }

  #skills {
    

    @media only screen and (max-width: 1149px){
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 200vh;
      h1 {
        font-size: 400%;
        margin-left: 0;
        align-self: center;
        text-align: center;
      }
      .skills-container {
        flex-direction: column;
        align-items: center;
        .skills {
          margin-bottom: 5%;
          width: 50%;
        }
      }
      
      
    }
    @media only screen and (max-width: 500px){
      .skills-container .skills {
        width: 70%;
      }
    }
    @media only screen and (max-width: 350px){
      .skills-container .skills {
        width: 80%;
      }
    }
  }
  #projects {

    @media only screen and (min-width: 1200px){
      height: 100vh;
    }
    @media only screen and (min-width: 1200px) and (max-height: 892px)  {
      height: 150vh;
    }

    @media only screen and (max-width: 1149px){
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .project-card {
        height: 380px;
      }
      h1 {
        font-size: 300%;
        text-align: center;
      }
      p {
        max-width: 90vw;
      }
      .project-img {
        height: 50%;
        width: 60%;
      }
      .project-carousel {
        height: 80vh;
      }
      .project-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tech-icon-container {
        display:none;
      }
    }
    @media only screen and (max-width: 900px) {
      p {
        max-width: 70vw;
        text-align: center;
      }
    }
    @media only screen and (max-width: 420px) {
      .project-img {
      display:none;
      }
      .project-button-container {
        flex-direction: column;
      }
    }
  }

  #experience{

    @media only screen and (max-width: 1149px){
      .column-flex-container, .experience-section {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 200vh;
      }

      h1 {
        font-size: 300%;
        text-align: center;
      }
      
      
    }

    @media only screen and (max-width: 987px) {
      
        flex-direction: column;
        justify-content: center;
        align-items: center;
      height: 110vh;
    }
    @media only screen and (max-width: 887px) {
      height: 120vh;
      .experience-container .content {
        font-size: 13px;
        margin-bottom: 2px;
        padding: 10px 20px 20px 10px;
      }
    }
    @media only screen and (max-width: 592px){
      height: 130vh;
    }
    @media only screen and (max-width: 535px){
      height: 135vh;
      max-height: 280vh;
      display: inline-block;
    }
  }

  #contact {

    @media only screen and (max-width: 1149px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .row-flex-container, .flex-row-centered {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      .contact-icons, .email-icon {
        font-size: 100%;
      }

      h1 {
        font-size: 300%;
        text-align: center;
        margin: 0;
        padding: 0;
      }
      
      
    }
  }

  #nav {

    @media only screen and (max-width: 1149px){
      .nav-name, .navbar-arrow {
        display: none;
      }
    }
  }